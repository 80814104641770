"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Page = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ids_components_1 = require("@iress/ids-components");
// eslint-disable-next-line @typescript-eslint/naming-convention -- this is not relevant
const react_1 = require("react");
require("@iress/ids-components/dist/style.css");
require("@iress/themes/global.css");
require("@iress/themes/build/css/iress-theme-light.css");
const TvShowResults_1 = require("./TvShowResults");
const layout_1 = require("./constants/layout");
const ApiHook_1 = require("./helpers/ApiHook");
const DebounceHook_1 = require("./helpers/DebounceHook");
require("./index.css");
const DEBOUNCE_TIME = 500;
const PAGE_HEADING = 'TV Show Search';
const Page = () => {
    const [userSearchTerm, setUserSearchTerm] = (0, react_1.useState)('');
    const debouncedSearchTerm = (0, DebounceHook_1.useDebounce)(userSearchTerm, DEBOUNCE_TIME);
    const { data, isLoading, hasError, setUrl } = (0, ApiHook_1.useTVShowApi)();
    (0, react_1.useEffect)(() => {
        if (debouncedSearchTerm.trim() !== '' && debouncedSearchTerm !== 'null') {
            setUrl(`/api/shows?q=${debouncedSearchTerm}`);
        }
    }, [debouncedSearchTerm, setUrl]);
    const onInputClear = (0, react_1.useCallback)(() => {
        setUserSearchTerm('');
    }, [setUserSearchTerm]);
    const onInputChange = (0, react_1.useCallback)((event) => {
        setUserSearchTerm(event.currentTarget.value);
    }, []);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: "search-container" }, { children: (0, jsx_runtime_1.jsx)(ids_components_1.IressContainer, { children: (0, jsx_runtime_1.jsxs)(ids_components_1.IressStack, Object.assign({ gutter: "xl" }, { children: [(0, jsx_runtime_1.jsxs)(ids_components_1.IressRow, Object.assign({ horizontalAlign: "center" }, { children: [(0, jsx_runtime_1.jsx)(ids_components_1.IressText, Object.assign({ element: "h1" }, { children: PAGE_HEADING })), (0, jsx_runtime_1.jsx)(ids_components_1.IressCol, Object.assign({ span: layout_1.PAGE_COL_SPAN }, { children: (0, jsx_runtime_1.jsx)(ids_components_1.IressInput, { clearable: true, onClear: onInputClear, onInput: onInputChange, placeholder: "Search", prepend: (0, jsx_runtime_1.jsx)(ids_components_1.IressIcon, { "data-testid": "search-icon", name: "search", screenreaderText: "Search" }), type: "search", watermark: true }) }))] })), (0, jsx_runtime_1.jsx)(TvShowResults_1.TvShowResults, { data: data, hasError: hasError, isLoading: isLoading })] })) }) })));
};
exports.Page = Page;
