"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Alert = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ids_components_1 = require("@iress/ids-components");
const layout_1 = require("./constants/layout");
const Alert = ({ message, status }) => {
    return ((0, jsx_runtime_1.jsx)(ids_components_1.IressRow, Object.assign({ horizontalAlign: "center" }, { children: (0, jsx_runtime_1.jsx)(ids_components_1.IressCol, Object.assign({ span: layout_1.PAGE_COL_SPAN }, { children: (0, jsx_runtime_1.jsx)(ids_components_1.IressAlert, Object.assign({ status: status }, { children: message })) })) })));
};
exports.Alert = Alert;
