"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageSkeleton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ids_components_1 = require("@iress/ids-components");
const skeleton = (key = 1) => ((0, jsx_runtime_1.jsx)(ids_components_1.IressCard, Object.assign({ "data-testid": "tv-show-skeleton", heading: (0, jsx_runtime_1.jsx)(ids_components_1.IressSkeleton, { textVariant: "h4" }), media: (0, jsx_runtime_1.jsx)(ids_components_1.IressSkeleton, { height: "295", mode: "rect", width: "210" }) }, { children: (0, jsx_runtime_1.jsx)(ids_components_1.IressSkeleton, { textVariant: "h4" }) }), key));
const PageSkeleton = ({ count = 1 }) => {
    const skeletons = Array.from({ length: count }, (item, index) => skeleton(index));
    return ((0, jsx_runtime_1.jsx)(ids_components_1.IressInline, Object.assign({ gutter: "lg", horizontalAlign: "center" }, { children: skeletons })));
};
exports.PageSkeleton = PageSkeleton;
