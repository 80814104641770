"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTVShowApi = void 0;
const react_1 = require("react");
const useTVShowApi = () => {
    const [data, setData] = (0, react_1.useState)([]);
    const [url, setUrl] = (0, react_1.useState)('');
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [hasError, setHasError] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            setHasError(false);
            setIsLoading(true);
            try {
                const apiResponse = yield fetch(url);
                const result = (yield apiResponse.json());
                setData(result);
            }
            catch (_a) {
                setHasError(true);
            }
            setIsLoading(false);
        });
        if (url.trim() !== '') {
            void fetchData();
        }
    }, [url]);
    return { data, hasError, isLoading, setUrl };
};
exports.useTVShowApi = useTVShowApi;
